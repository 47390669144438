import React, { useEffect } from "react";
import { NextPage } from "next";
import { isEmpty } from "lodash";
import { ELandingType } from "@enums/searchHit.enum ";
import { TMegaMenu } from "@@types/megaMenu";
import { useMegaMenuStore } from "@stores/megaMenu.store";

type MegaMenuHocProps<T> = T & TMegaMenu;

const withMegaMenu = <T,>(WrappedComponent: NextPage<T>) => {
  const WithMegaMenu = (props: MegaMenuHocProps<T>) => {
    const { landings, type = ELandingType.BY_LOCATION } = props;
    const { setMegaMenuStore } = useMegaMenuStore();

    useEffect(() => {
      if (!isEmpty(landings)) {
        setMegaMenuStore(type, landings);
      }
    }, [landings, setMegaMenuStore, type]);

    return <WrappedComponent {...props} />;
  };

  return WithMegaMenu;
};

export default withMegaMenu;
