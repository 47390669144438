import { AuthContext } from "@context";
import useOpenWidgetCreateAccount from "@hooks/magnetoUi/useOpenWidgetToApply";
import { useCallback, useContext, useEffect, useState } from "react";

const useAutomaticRegisterModal = () => {
  const key = "automaticRegisterModal";
  const [count, setCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { openCreateAccount } = useOpenWidgetCreateAccount();
  const { isAuthenticated } = useContext(AuthContext);

  const handleIntervalLogic = useCallback(
    (interval: NodeJS.Timeout) => {
      if (!isOpen) {
        setCount((prevCount) => {
          const newCount = prevCount + 1;
          sessionStorage.setItem(key, newCount.toString());
          openCreateAccount();
          setIsOpen(true);

          if (newCount >= 3) {
            clearInterval(interval);
          }

          setTimeout(() => {
            const btnClose = document.querySelector(".widget-signup .ant-modal-close .ant-modal-close-x");
            const handleClose = () => {
              setIsOpen(false);
            };
            btnClose && btnClose.addEventListener("click", handleClose);
          }, 3000);

          return newCount;
        });
      }
    },
    [openCreateAccount, isOpen]
  );

  useEffect(() => {
    if (isAuthenticated) return;

    const storedCount = Number(sessionStorage.getItem(key)) || 0;
    setCount(storedCount);

    if (storedCount >= 3) {
      return;
    }

    if (isOpen) return;

    const interval: ReturnType<typeof setInterval> = setInterval(() => handleIntervalLogic(interval), 40000);

    return () => {
      clearInterval(interval);
    };
  }, [isAuthenticated, isOpen, handleIntervalLogic]);

  return count;
};

export default useAutomaticRegisterModal;
